@tailwind base;
@tailwind components;
@tailwind utilities;


body{
    background-color: #111827;
    color: #fff;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.config-table th{
    font-weight: 600;
    text-align: left;
    padding: 0.6em;
}

.config-table td{
    padding: 0.6em;
}

.button{
    background-color: #65a30d;
    color: #fff;
    padding: 0.6em 1em;
    border-radius: 0.7em;
    text-decoration: none;
    font-weight: 600;
    display: inline-block;
    margin: 0.6em;
    transition: background-color 0.2s ease-out;
}

.button:hover{
  background-color: #4d7c0f;
}

.connect-container{
  margin-bottom: 30px;
}

.connect-container > div{
  justify-content: center;
}

.track-nft-timeline{
    font-weight: medium;
    font-family: monospace;
    width: 100%;
    overflow-x: scroll;
    padding: 1rem;
}

.track-nft-timeline h2{
    font-weight: bold;   
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

.track-nft-card{
    min-width: 240px;
    max-width: 240px;
    height: 100px;
    border-right: 1px solid #52525b;
    padding-left: 2em;
    padding-right: 1em;
}

.track-nft-card:first-child{
    padding-left: 0;
}

.track-nft-card:last-child{
    border-right: none;
}

.thank-you-screen h2{
    background-image: linear-gradient(90deg,#5353fe,#b9aefd);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.nft-image{
    width: 70px;
    height: 70px;
    border-radius: 10px;
    overflow: hidden;
}

.lds-ring {
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  left: 50%;
  top: 50%; 
  opacity: 0.2;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 30px;
  height: 30px;
  margin: 8px;
  border: 3px solid #404652;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

